import React from 'react'

//import components
import Project from '../components/Project'
import Padding from '../components/Padding'
import Image from '../components/Image'
import NextSections from '../components/NextSections'
import Grid from '../components/Grid'

// import images
import full1 from '../images/kids-swim-merch/swim1.jpg'
import full2 from '../images/kids-swim-merch/swim2.jpg'
import supporting1 from '../images/kids-swim-merch/kids-supporting-1.jpg'
import supporting2 from '../images/kids-swim-merch/kids-supporting-2.jpg'

export default () => (
    <Project
        title={`kid's swim merchandise`}
            date={'2018'}
            position={'Graphic Designer'}
            employer={`Boscov's Department Store`}
            brief={[
                'Swimsuit season was on the horizon and the Visual Department wanted to market kid’s swim in a new and fun way.',
                'This concept and final design was a collaboration between other members of the Visual Merchandising Team and myself. I took creative direction from one of the Creative Managers to produce a set of swimwear posters; one for girls and one for boys. The result was an illustrative take on visual merchandising that would entice any young tween.'
            ]}
            skills={[
                'Illustration & Layout Design',
                'Photo Manipulation',
                'Large Format Printing'
            ]}
    >
        <Grid
            images={[
                {
                    src: full1,
                    alt: 'Girls on hand-drawn towels'
                },
                {
                    src: full2,
                    alt: 'Boys on hand-drawn water'
                }
            ]}
            columns={2}
            background={'#e9e9e9'}
        />
        <Padding />
        <Image src={supporting1} alt={'Poster in a department store'} size={800} />
        <Padding />
        <Image src={supporting2} alt={'Poster in a department store'} size={800} />
        <Padding />
        <NextSections
            previous={'petite-week-merchandise'}
            next={'toothbrush-packaging'}
        />
    </Project>
)