import React from 'react'

const Image = ({src, alt, size, shadow=false}) => {
    let applyShadow
    if (shadow){
        applyShadow = '5px 5px 6px rgba(0,0,0,.2)'
    }
    return (
        <div style={{
            maxWidth: `${size}px`,
            width: '80%',
            margin: '0 auto',
            boxShadow: applyShadow
        }}>
            <img 
                src={src}
                alt={alt}
                style={{
                    width: '100%'
                }}
            />
        </div>
    )
}

export default Image